import React from 'react'
import { Link } from 'react-router-dom'

const CookiesPolicy = () => {
    return (
        <div>
            <div className="nav-section">
                <span>
                    <Link to="/" className="nav-btn-link">
                        Home
                    </Link>
                    / Cookies Policy
                </span>
            </div>
            <section className='p-5'>
                <div className="container px-4 d-flex flex-column align-items-center gap-3">
                    <h2>Cookies Policy</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus ullam, assumenda minima magnam doloremque voluptate, atque suscipit, mollitia aut vel corrupti asperiores quisquam modi quis. Laboriosam aspernatur nisi harum totam.</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, cum eligendi voluptatem accusantium omnis ipsam consequatur, magni obcaecati deserunt sequi perferendis in quam blanditiis suscipit nam deleniti? Sunt, nobis minus.
                        Eveniet quod repudiandae magnam natus rem enim ullam nam perspiciatis earum eaque consectetur exercitationem expedita doloribus impedit, minus aspernatur! Veritatis rem nisi ullam quaerat. Repellat commodi sequi illo quasi reiciendis.
                        Iure, maxime eligendi! Aperiam, accusantium autem.</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque alias tempora quod? Ullam pariatur at quidem assumenda perferendis, quis ut voluptates aperiam molestiae error veritatis! Eaque ullam architecto omnis explicabo!
                        Neque quas vitae, ullam veniam quo assumenda, voluptatem ut harum recusandae perspiciatis fugit tenetur nisi aliquam minus at molestias culpa veritatis repellendus, qui eos optio eum facilis? Necessitatibus, ipsam nemo!</p>
                </div>
            </section>
        </div>
    )
}

export default CookiesPolicy
