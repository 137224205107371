import React from "react";
import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FiMenu } from "react-icons/fi";
import ListGroup from "react-bootstrap/ListGroup";

import Collapse from "react-bootstrap/Collapse";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";

import { FaInstagram } from "react-icons/fa";
import { IoLogoFacebook } from "react-icons/io";
import { IoLogoLinkedin } from "react-icons/io";
import { FaSquarePhone } from "react-icons/fa6";

import { Link } from "react-router-dom";

function OffCanvas() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [open, setOpen] = useState(false);


  const redirectTo = (url) => {
    if (url.startsWith("http://") || url.startsWith("https://")) {
      window.open(url, "_blank"); // External link
      setShow(true);
    }
  };

  const makePhoneCall = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
    setShow(true);
  };

  return (
    <>
      <div className="  fs-3 me-2 text-white mt-1 " onClick={handleShow}>
        <FiMenu />
      </div>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header
          style={{ backgroundColor: "var(--bs-secondary)" }}
          closeButton
        >
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ListGroup>
            <ListGroup.Item action>
              <Link
                to={"/"}
                className="nav-btn-link text-dark"
                onClick={() => setShow(!show)}
              >
                Home
              </Link>
            </ListGroup.Item>
            <ListGroup.Item action>
              <Link
                to={"/about"}
                className="nav-btn-link text-dark"
                onClick={() => setShow(!show)}
              >
                About Us
              </Link>
            </ListGroup.Item>

            <ListGroup.Item action>
              <div
                className="d-flex justify-content-between align-items-center"
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
              >
                <span>Categories </span>{" "}
                <span className="fs-5">
                  {" "}
                  {open ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
                </span>
              </div>
              <Collapse in={open}>
                <div id="example-collapse-text">
                  <ListGroup className="mt-2">
                    <ListGroup.Item action>
                      <Link
                        // to={"/categories/apparels"}
                        className="nav-btn-link text-dark"
                        onClick={() => setShow(!show)}
                      >
                        Apparels
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item action>
                      <Link
                        // to={"/categories/bathskin"}
                        className="nav-btn-link text-dark"
                        onClick={() => setShow(!show)}
                      >
                        Bath & Skin
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item action>
                      <Link
                        // to={"/categories/diapering"}
                        className="nav-btn-link text-dark"
                        onClick={() => setShow(!show)}
                      >
                        Diapering
                      </Link>
                    </ListGroup.Item>

                    <ListGroup.Item action>
                      <Link
                        // to={"/categories/feeding"}
                        className="nav-btn-link text-dark"
                        onClick={() => setShow(!show)}
                      >
                        Feeding
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item action>
                      <Link
                        // to={"/categories/gifts"}
                        className="nav-btn-link text-dark"
                        onClick={() => setShow(!show)}
                      >
                        Gifts & Decor
                      </Link>{" "}
                    </ListGroup.Item>
                    <ListGroup.Item action>
                      <Link
                        // to={"/categories/baby"}
                        className="nav-btn-link text-dark"
                        onClick={() => setShow(!show)}
                      >
                        Baby Gear
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item action>
                      <Link
                        // to={"/categories/nursery"}
                        className="nav-btn-link text-dark"
                        onClick={() => setShow(!show)}
                      >
                        Nursery
                      </Link>{" "}
                    </ListGroup.Item>
                    <ListGroup.Item action>
                      <Link
                        // to={"/categories/toys"}
                        className="nav-btn-link text-dark"
                        onClick={() => setShow(!show)}
                      >
                        Toys
                      </Link>{" "}
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </Collapse>
            </ListGroup.Item>
            <ListGroup.Item action>
              {" "}
              <Link
                to={"/"}
                className="nav-btn-link text-dark"
                onClick={() => setShow(!show)}
              >
                Our Stories
              </Link>
            </ListGroup.Item>
            <ListGroup.Item action>
              <Link
                to={"/contact"}
                className="nav-btn-link text-dark"
                onClick={() => setShow(!show)}
              >
                Contact Us
              </Link>
            </ListGroup.Item>
          </ListGroup>
          <div className="d-flex align-items-center gap-3 mt-5">
            <div
              onClick={() => redirectTo("https://www.instagram.com/cribbies_perinthalmanna?igsh=cWozZHNzczV1bzZy")}
              className=" p-2 border border-1 d-flex align-items-center justify-content-center rounded-circle fs-4 media-link"
              target="_blank"
            >
              <FaInstagram />
            </div>
            <div
              onClick={() => redirectTo("https://www.facebook.com/profile.php?id=61559962992097")} className="  p-2 border border-1 d-flex align-items-center justify-content-center rounded-circle fs-4 media-link"

            >
              <IoLogoFacebook />
            </div>
            <div
              onClick={() => redirectTo("https://www.linkedin.com/company/cribbies/?viewAsMember=true")}
              className="  p-2 border border-1 d-flex align-items-center justify-content-center rounded-circle fs-4 media-link"

            >
              <IoLogoLinkedin />
            </div>

            <div
              onClick={() => makePhoneCall("+918606863555")}
              className="  p-2 border border-1 d-flex align-items-center justify-content-center rounded-circle fs-4  media-link"

            >
              <FaSquarePhone />
            </div>
            
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffCanvas;
