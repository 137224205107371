import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../Assets/logo.png";
import { TfiLocationPin } from "react-icons/tfi";
import OffCanvas from "./OffCanvas";

const Header = () => {
  return (
    <div className="header">
      <Navbar fixed="top"  expand="lg" style={{ backgroundColor: "var(--bs-primary)" }} className="py-3 shadow-lg">
        <div className="container-fluid px-4 ">
  
        
              <Nav.Link className="text-white d-flex align-items-center">
                <OffCanvas />
              </Nav.Link>
      
              <Navbar.Brand href="/" className="navbar-brand d-flex align-items-center">
                <img className="img-fluid" src={logo} alt="" />
              </Navbar.Brand>
        
  
          <nav className="ms-auto">
            <Nav.Link href="https://maps.app.goo.gl/B8ANcf1GGoBcwyJV7" target="_blank" className="text-white fs-5 fw-bold">
              <TfiLocationPin />
            </Nav.Link>
          </nav>
        </div>
      </Navbar>
    </div>
  );
};

export default Header;
