import React, { useRef } from 'react';
import emailjs from '@emailjs/browser'
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FaEnvelope, FaWhatsapp } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import { Link } from "react-router-dom";


const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_wewhy2j', 'template_eb6ovb7', form.current, {
      publicKey: 'zge1EZVr2aLWNU57q',
    })
    e.target.reset();
  };

  return (
    <div className="contact ">
      <div className="nav-section">
        <span>
          <Link to="/" className="nav-btn-link">
            Home
          </Link>
          / Contact Us
        </span>
      </div>
      <Container className="mb-5 ">
        <Row className="text-center">
          <Col>
            <h1 className="my-4 ">
              Contact Us
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="colone">
            <h3>Get In Touch</h3>
            <Form className="mt-4" ref={form} onSubmit={sendEmail}>
              <Form.Group controlId="formName" className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" name='name' placeholder="Insert your Name" />
              </Form.Group>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name='email' placeholder="Insert your Email" />
              </Form.Group>
              <Form.Group controlId="formProject" className="mb-3">
                <Form.Label>Project</Form.Label>
                <Form.Control
                  as="textarea"
                  name='message'
                  rows={4}
                  placeholder="Write your message"
                />
              </Form.Group>
              <Button type="submit" className="border-0" style={{ backgroundColor: "var(--bs-primary)" }}>
                Send Message <IoIosSend />
              </Button>
            </Form>
          </Col>

          <Col md={6} className="coltwo">
            <h3>Talk to Us</h3>
            <div className="contact-box p-2 mb-3 mt-4 border rounded">
              <FaEnvelope size={30} />
              <h5>Email</h5>
              <p>cribbies@outlook.com</p>
              <Button variant="">
                <a className="text-decoration-none" href="mailto:cribbies@outlook.com" target='_blank'>Write Us →</a>
              </Button>
            </div>
            <div className="contact-box p-2 mb-3 border rounded">
              <FaWhatsapp size={30} />
              <h5>WhatsApp</h5>
              <p>+91 8360800400</p>
              <Button variant="">
                <a className="text-decoration-none" href="https://api.whatsapp.com/send?phone=+918360800400&text=Hello, more information!" target='_blank'>
                  Write Us →
                </a>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
