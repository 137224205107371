import React from "react";
import "./scrollup.css";
import { BiUpArrowAlt } from "react-icons/bi";

const ScrollUp = () => {
  window.addEventListener("scroll", function () {
    const scrollUp = document.querySelector(".scrollup");
    // when the scroll is  higher than 560 viewport height , add the show-scroll class to a tag with the scroll-top class
    if (this.scrollY >= 190) scrollUp.classList.add("show-scroll");
    else scrollUp.classList.remove("show-scroll");

  });

  return (
    <a href="" className="scrollup">
      <BiUpArrowAlt className=" scrollup__icon" />
    </a>
  );
};

export default ScrollUp;
