import React from "react";
import Card from "react-bootstrap/Card";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Categories({imgArray,categories}) {
 
  return (
    <div className="categories">
      <div className="nav-section">
        <span>
          <Link to="/" className="nav-btn-link">
            Home
          </Link>{" "}
          / Categories / {categories.name}
        </span>
      </div>
      <Container className="d-flex justify-content-center align-items-center flex-wrap">
        <Row className="justify-content-center align-items-center ">
          <h1 className="text-center py-3">
            {categories.name}
          </h1>
          {imgArray.map((imageObj) => (
            <Col
              key={imageObj.id}
              xs={12}
              md={6}
              lg={4}
              className="d-flex justify-content-center mb-5"
            >
              <Card className="rounded shadow-lg">
                <div
                  className="image-container"
                  style={{
                    overflow: "hidden",
                    position: "relative",
                    width: "100%",
                    height: "45vh",
                  }}
                >
                  <img
                    variant="top"
                    src={imageObj.img}
                    className="img-fluid rounded"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                      transition: "transform 0.5s ease",
                    }}
                    alt="Card image"
                  />
                </div>
              </Card>
            </Col>
          ))}
        </Row>
        <section className="middle">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-10 col-xl-10 text-center">
                <p className="ft-regular fs-lg text-dark">
                  {categories.discription}
                </p>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
}

export default Categories;
