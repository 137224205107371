import React from "react";
import logoanmi from "../Assets/logoanmi.mp4";
import Slider from "../Components/Slider";

const Home = () => {
  return (
    // home page
    <>
      <section className="p-0 Banner">
        <div className="home-slider margin-bottom-0">
          <div className="item">
            {/* <img
              src={homeimg1}
              alt=""
              className="w-100 h-100"
              style={{
                objectFitit: "cover",
                maxHeight: "calc(calc(100vh - 60px))",
              }}
            /> */}
            <video
              src={logoanmi} // Replace with the actual video source path
              className="w-100 h-100"
              style={{
                objectFit: "cover",
                maxHeight: "calc(100vh - 60px)",
              }}
              autoPlay
              muted
              playsInline
            ></video>
          </div>
        </div>
      </section>

      <section
        className="middle"
        style={{ backgroundColor: "var(--bs-secondary)" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-10 col-xl-10 py-5 text-center">
              <p className="ft-regular fs-lg text-dark">
              At Cribbies, we believe that parenthood is a beautiful journey filled with wonder and milestones. We're here to support you every step of the way, from those precious newborn moments to your child's playful sixth year.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Slider />
    </>
  );
};

export default Home;
