import React from "react";
import { Link } from "react-router-dom";
import { Container, } from "react-bootstrap";

function Categories01({ imgArray, categories }) {
  return (
    <div className="categories">
      <div className="nav-section">
        <span>
          <Link to="/" className="nav-btn-link">
            Home
          </Link>{" "}
          / Categories / {categories.name}
        </span>
      </div>
      <Container className="d-flex justify-content-center align-items-center flex-wrap">
        <div className="row">
          <h1 className="text-center py-3">{categories.name}</h1>

          {imgArray.map((images, index) => (
            <div key={index} className={images.colClass}>
              <img
                src={images.img}
                alt={images.alt}
                className="img-fluid rounded"
              />
            </div>
          ))}
        </div>
        <section className="middle">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-10 col-xl-10 text-center">
                <p className="ft-regular fs-lg text-dark">
                  {categories.discription}
                </p>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
}

export default Categories01;
