import React from "react";
import { BsBuildings } from "react-icons/bs";
import { FaInstagram } from "react-icons/fa";
import { IoLogoFacebook } from "react-icons/io";
import { IoLogoLinkedin } from "react-icons/io";
import Logo from "../Assets/logoCribbies.png";
import { Link } from "react-router-dom";
import { FaSquarePhone } from "react-icons/fa6";

const Footer = () => {
  const makePhoneCall = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <footer className="pt-5 px-5 pb-3">
      <div>
        <div className="row">
          <div className="col-md-4 d-flex flex-column gap-4 mb-5">
            <div>
              <img
                src={Logo}
                alt="cribbies"
                className="mb-2 img-fluid footer-logo"
              />
              <p>
                Cribbies is Perinthalmanna’s largest baby shop, backed by a team
                with over 10 years of experience in the baby care industry.{" "}
              </p>
              <span>Cribbies : Perinthalmanna, Kerala 679322</span>
            </div>
            <div className="d-flex align-items-center gap-3">
              <a
                href="https://www.instagram.com/cribbies_perinthalmanna?igsh=cWozZHNzczV1bzZy"
                target="_blank"
                className="icon p-2 border border-1 d-flex align-items-center justify-content-center rounded-circle fs-4"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61559962992097"
                target="_blank"
                className="icon p-2 border border-1 d-flex align-items-center justify-content-center rounded-circle fs-4"
              >
                <IoLogoFacebook />
              </a>
              <a
                href="https://www.linkedin.com/company/cribbies/?viewAsMember=true"
                target="_blank"
                className="icon p-2 border border-1 d-flex align-items-center justify-content-center rounded-circle fs-4"
              >
                <IoLogoLinkedin />
              </a>
              <div
                onClick={() => makePhoneCall("+918360800400")}
                className="icon p-2 border border-1 d-flex align-items-center justify-content-center rounded-circle fs-4"
              >
                <FaSquarePhone />
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-6">
            <div className="d-flex gap-5 flex-wrap">
              {" "}
              <div>
                <h5 className="text-uppercase">The company</h5>
                <ul className="list-unstyled d-flex flex-column gap-2">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/">Categories</Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
              <div>
                <h5 className="text-uppercase">Categories</h5>
                <ul className="list-unstyled d-flex flex-column gap-2">
                  <li>
                    <Link
                    // to="/categories/apparels"
                    >
                      Apparels
                    </Link>
                  </li>
                  <li>
                    <Link
                    // to="/categories/bathskin"
                    >
                      Bath & Skin
                    </Link>
                  </li>
                  <li>
                    <Link
                    // to="/categories/diapering"
                    >
                      Diapering
                    </Link>
                  </li>
                  <li>
                    <Link
                    //  to="/categories/feeding"
                    >
                      Feeding
                    </Link>
                  </li>
                  <li>
                    <Link
                    // to="/categories/gifts"
                    >
                      Gift & Decor
                    </Link>
                  </li>
                  <li>
                    <Link
                    //  to="/categories/baby"
                    >
                      Baby gear
                    </Link>
                  </li>
                  <li>
                    <Link
                    //  to="/categories/nursery"
                    >
                      Nursery
                    </Link>
                  </li>
                  <li>
                    <Link
                    //  to="/categories/toys"
                    >
                      Toys
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h5 className="text-uppercase">Pages</h5>
                <ul className="list-unstyled d-flex flex-column gap-2">
                  <li>
                    <Link to="/privacyPolicy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/termsOfUse">Terms of Use</Link>
                  </li>
                  <li>
                    <Link to="/cookiesPolicy">Cookies Policy</Link>
                  </li>
                  <li>
                    <Link to="/refundPolicy">Refund Policy</Link>
                  </li>
                </ul>
              </div>
              <div>
                <h5 className="text-uppercase">Nearby store</h5>
                <ul className="list-unstyled d-flex flex-column gap-2">
                  <li>
                    <a
                      href="https://maps.app.goo.gl/B8ANcf1GGoBcwyJV7"
                      target="_blank"
                    >
                      <BsBuildings /> Perinthalmanna
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="d-flex align-items-center justify-content-center copy-right">
          <span>© www.cribbiesstore.com All rights reserved </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
