import React from 'react'
import { Link } from 'react-router-dom'
import BrandImg from '../Assets/Cribbies Brand.jpg'

const AboutUs = () => {
    return (
        <section className='about' id='about'>
            <div className='nav-section'>
                <span><Link to="/" className='nav-btn-link'>Home</Link> / About Us</span>
            </div>
            <div className='container my-5'>
                <div className="row">
                    <div className="col-md-6">
                        <div className='d-flex flex-column gap-3'>
                            <h2>The Cribbies Story</h2>
                            <p>Cribbies is Perinthalmanna’s largest baby shop, established with 10 years of baby care industry experience. Our focus is on providing everything you need for your child, from newborn essentials to products for 6-year-olds.</p>
                            <p>We believe that every parent deserves to feel happy and confident on their parenting journey. We offer a curated selection of high-quality branded baby products alongside expert advice and support. Whether you’re expecting your first child or expanding your family, we’re here to guide you through every stage with essential items and a warm, welcoming environment.
                            </p>
                            <p>At Cribbies, and we actively seek talented individuals to join our team. Whether you’re in retail, back office, Digital Design or marketing, your passion for family and the joys of parenthood will be at the heart of everything you do.</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img className='img-fluid rounded w-100 object-fit-cover' src={BrandImg} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs