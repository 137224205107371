import React from 'react'
import Categories from '../Components/Categories'
import homeimg from "../Assets/homeimg.jpg";


const Apparels = () => {
     // Assuming you have an array of image objects
  const imgArray = [
    { id: 1, img: homeimg },
    { id: 2, img: homeimg },
    { id: 3, img: homeimg },
    { id: 4, img: homeimg },
    { id: 5, img: homeimg },
    { id: 6, img: homeimg },
  ];
  const categories =  {name :"Apparels" , 
  discription : " lorm Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem dolorum ipsum molestiae, cupiditate saepe, voluptate odio porro accusantium reiciendis ad nesciunt incidunt, sapiente assumenda aperiam sunt totam doloremque. Iste, soluta?" } 

  return (
    <>
    <Categories imgArray={imgArray} categories={categories} />
    </>
  )
}

export default Apparels