import React from "react";
import Categories01 from "../Components/Categories01";
import homeimg from "../Assets/homeimg.jpg";


const Feeding = () => {
  const imgArray = [
    { id: 1, img: homeimg, colClass: "col-md-4 col-sm-6 mb-4" },
    { id: 2, img: homeimg, colClass: "col-md-4 col-sm-6 mb-4" },
    { id: 3, img: homeimg, colClass: "col-md-4 col-sm-6 mb-4" },
    { id: 4, img: homeimg, colClass: "col-md-6 col-sm-6 mb-4" },
    { id: 5, img: homeimg, colClass: "col-md-6 col-sm-6 mb-4" },
  ];

  const categories = {
    name: "Feeding",
    discription:
      " lorm Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem dolorum ipsum molestiae, cupiditate saepe, voluptate odio porro accusantium reiciendis ad nesciunt incidunt, sapiente assumenda aperiam sunt totam doloremque. Iste, soluta?",
  };

  return (
    <>
      <Categories01 imgArray={imgArray} categories={categories} />
    </>
  );
};

export default Feeding;
