import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation"; // Import navigation styles
// import required modules
import { Pagination, Navigation } from "swiper/modules";
import Image1 from "../Assets/homeimg.jpg";
import { Link } from "react-router-dom";
import Apparels from "../Assets/clothes.png";
import Bath from "../Assets/bath.png";
import DIAPERING from "../Assets/DIAPERING.png";
import FEEDING from "../Assets/FEEDING.png";
import GIFTS from "../Assets/GIFTS.png";
import BABY from "../Assets/baby Gear.png";
import NURSERY from "../Assets/NURSERY.png";
import TOYS from "../Assets/TOYS.png";


const Slider = () => {
  const categories = [
    { name: "Apparels", img:  Apparels , link: "/categories/apparels" },
    { name: "Bath & Skin", img:  Bath , link: "/categories/bathskin" },
    { name: "Diapering", img:  DIAPERING , link: "/categories/apparels" },
    { name: "Feeding", img:  FEEDING , link: "/categories/apparels" },
    { name: "Gift & Decor", img:  GIFTS , link: "/categories/apparels" },
    { name: "Baby Gear", img:  BABY , link: "/categories/apparels" },
    { name: "Nursery", img:  NURSERY , link: "/categories/apparels" },
    { name: "Toys", img:  TOYS , link: "/categories/apparels" },
  ];

  return (
    <section
      id="categories"
      className="slider-section py-5"
      style={{ color: "var(--bs-primary)" }}
    >
      <div className="container-fluid mb-2">
        <div className="section-heading py-4 pb-3">
          <h1 className=" text-center">
            Categories
          </h1>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          navigation // Enable navigation
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 50,
            },
          }}
          modules={[Pagination, Navigation]} // Include Navigation module
          className="py-5 px-5"
        >
          {categories?.map((item,index) => (
            <SwiperSlide key={index} className="d-flex align-items-center justify-content-center ">
              <Link
                // to={item.link}
                className="text-decoration-none rotate-hover"
              >
                <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                  <img
                    height={"165px"}
                    width={"165px"}
                    src={item?.img}
                    alt=""
                    className="rounded-circle"
                  />
                  <span className="fw-semibold">{item.name}</span>
                </div>
              </Link>
            </SwiperSlide>
          ))}

          {/* <SwiperSlide className='d-flex align-items-center justify-content-center'>
                        <div className='d-flex flex-column align-items-center justify-content-center gap-3'>
                            <img height={'165px'} width={'165px'} src={Image1} alt="" className='rounded-circle' />
                            <span className='fw-semibold'>Bath & Skin</span>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='d-flex align-items-center justify-content-center'>
                        <div className='d-flex flex-column align-items-center justify-content-center gap-3'>
                            <img height={'165px'} width={'165px'} src={Image1} alt="" className='rounded-circle' />
                            <span className='fw-semibold'>Diapering</span>
                        </div>
                    </SwiperSlide >
                    <SwiperSlide className='d-flex align-items-center justify-content-center'>
                        <div className='d-flex flex-column align-items-center justify-content-center gap-3'>
                            <img height={'165px'} width={'165px'} src={Image1} alt="" className='rounded-circle' />
                            <span className='fw-semibold'>Feeding</span>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='d-flex align-items-center justify-content-center'>
                        <div className='d-flex flex-column align-items-center justify-content-center gap-3'>
                            <img height={'165px'} width={'165px'} src={Image1} alt="" className='rounded-circle' />
                            <span className='fw-semibold'>Gift & Decor</span>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='d-flex align-items-center justify-content-center'>
                        <div className='d-flex flex-column align-items-center justify-content-center gap-3'>
                            <img height={'165px'} width={'165px'} src={Image1} alt="" className='rounded-circle' />
                            <span className='fw-semibold'>Baby Gear</span>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='d-flex align-items-center justify-content-center'>
                        <div className='d-flex flex-column align-items-center justify-content-center gap-3'>
                            <img height={'165px'} width={'165px'} src={Image1} alt="" className='rounded-circle' />
                            <span className='fw-semibold'>Nursery</span>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='d-flex align-items-center justify-content-center'>
                        <div className='d-flex flex-column align-items-center justify-content-center gap-3'>
                            <img height={'165px'} width={'165px'} src={Image1} alt="" className='rounded-circle' />
                            <span className='fw-semibold'>Toys</span>
                        </div>
                    </SwiperSlide> */}
        </Swiper>
      </div>
    </section>
  );
};

export default Slider;
