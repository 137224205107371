import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

import { Route, Routes, useLocation } from 'react-router-dom';
import Header from './Components/Header';
import Home from './Pages/Home';
import Footer from './Components/Footer';
import Contact from './Pages/Contact';
import Categories from './Components/Categories';
import AboutUs from './Pages/AboutUs';
import ScrollUp from './Components/scrollUP/ScrollUp';
import Apparels from './Pages/Apparels';
import BathSkin from './Pages/BathSkin';
import Diapering from './Pages/Diapering';
import Feeding from './Pages/Feeding';
import Gifts from './Pages/Gifts';
import Baby from './Pages/Baby';
import Nursery from './Pages/Nursery';
import Toys from './Pages/Toys';
import { useEffect } from 'react';
import RefundPolicy from './Pages/RefundPolicy';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsOfUse from './Pages/TermsOfUse';
import CookiesPolicy from './Pages/CookiesPolicy';

function App() {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])

  return (
    <>
      <Header />

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/about' element={<AboutUs />} />
        <Route path='/categories/apparels' element={<Apparels />} />
        <Route path='/categories/bathskin' element={<BathSkin />} />
        <Route path='/categories/diapering' element={<Diapering />} />
        <Route path='/categories/feeding' element={<Feeding />} />
        <Route path='/categories/gifts' element={<Gifts />} />
        <Route path='/categories/baby' element={<Baby />} />
        <Route path='/categories/nursery' element={<Nursery />} />
        <Route path='/categories/toys' element={<Toys />} />
        <Route path='/refundPolicy' element={<RefundPolicy />} />
        <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
        <Route path='/termsOfUse' element={<TermsOfUse />} />
        <Route path='/cookiesPolicy' element={<CookiesPolicy />} />
      </Routes>

      <Footer />

      <ScrollUp />

    </>
  );
}

export default App;
